import React, { useEffect, useState } from "react"
import HomeLayout from "../layouts/HomeLayout"
import InstagramEmbed from 'react-instagram-embed';
import { Grid} from "@material-ui/core"
import firebase from 'firebase';
import './whatsapp.css'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const config = {
  apiKey: "AIzaSyBC2pJG3stupZ7jqXfpPBGomHtsyTHoF1I",
  authDomain: "harrisongroup-f988e.firebaseapp.com",
  projectId: "harrisongroup-f988e",
  storageBucket: "harrisongroup-f988e.appspot.com",
  messagingSenderId: "926329189481",
  appId: "1:926329189481:web:1cd98c436a06e723a9def8",
  measurementId: "G-Z1K41N9E02"
};
  firebase.initializeApp(config);
  class Spinner extends React.Component {
    render() {
      return (
        <div className="spinner-box">
          <div className="loading-icon">
          </div>
          <span>Loading...</span>
        </div>
      )
    }
  }
   
const MyComponent = () => {
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    firebase.firestore().collection("url").get().then((response) => {
      console.log(response.docs)
      if (response.docs && response.docs.length){
     setData(response.docs)
     setLoading(false);
    }})
  }, [])
  
  return (
    <HomeLayout> 
        <div style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={0} align="center" justify="center">
        <div style={{}} className="row">

      {loading && data.length == 0 ? <Spinner/> : null}
      {data && data.map(instagram =>{
         return (
          <Grid
          item
          xs={12}
          lg={4}
          xl={3}
          md={2}
          sm={2}
          style={{
            width: "105%",
            padding: 20,
          }}
        >
          <InstagramEmbed
          url={instagram.data().url}
          clientAccessToken='304550061306612|d715ad29a248fa4232fc3304e85e0c42'
          maxWidth={350}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
          
        />
       </Grid>
         )
      })}

  </div>  
</Grid>
</div>
  <a
        href="https://wa.me/+917708106595?text=Welcome"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon style={{fontSize:"30px"}}/>
      </a>
    </HomeLayout>
  )
}

export default MyComponent
